export const ROUTES = {
  home: '/home',
  property: '/property',
  printReport: '/print-report',
  users: '/users',
  setting: '/settings',
  roomAndExtras: '/rooms-and-extras',
  viewInvoice: '/view-invoice',
  settingsPriceRestrictionPlan: '/price-and-restrictions',
  propertyPolicies: '/property-and-policies',
  bookingEngine: '/booking-engine',
  specialOfferAndPromotion: '/special-offer-and-promotion',
  printReservaion: '/print-reservation',
  channels: '/channels',
  reservation: '/reservation',
  reservationList: '/reservation-list',
  newReservation: '?new-reservation=open',
  expenses: '/expenses',
  boardsAndTaxes: '/boards-and-taxes',
  authomaticEmails: '/automatic-emails',
  languages: '/languages',
  marketPlace: '/market-place',
  general: '/general',
  settingPermisions: '/permissions',
  settingsSubscription: '/subscription',
  activityLog: '/activity-log',
  accountSetting: '/account-setting',
  people: '/people',
  companies: '/companies',
  calendar: '/calendar',
  multiCalendar: '/multi-calendar',
  invoices: '/invoices',
  newInvoice: '/new-invoices',
  editInvoice: '/edit-invoice',
  reports: '/reports',
  statistics: '/statistics',
  digitalKey: '/digital-key',
  getRoomKey: '/get-room-key/',
  noRoomKey: '/page-not-found',
  subscription: '/subscription',
  checkout: '/checkout',
  booking_engine_preview: '/booking-engine-preview',
  multipleCalendar: '/multi-calendar',
  compactCalendar: '/compact-calendar',
  yieldCalendar: '/yield-calendar',
  floorCalendar: '/floor-calendar',
  pricingRestriction: '/pricing-and-restriction',
  openDoorWithDigitalKey: '/open-door-with-digital-key',
  Lost_And_Found: '/lost-and-found',
  reportsInvoices: '/reports-invoices',
  Restaurant_Services: '/restaurant-services',
  Additional_Services: '/additional-services',
  Company_Revenue: '/company-revenue',
  countryRevenue: '/country-revenue',
  reservationServicesRevenue: '/reservation-services-revenue',
  Board: '/board',
  allRevenue: '/all-revenue',
  Monthly_Revenue: '/monthly-revenue',
  Channel_Revenue: '/channel-revenue',
  allInvoices: '/all-Invoices-reports',
  noshowInvoices: '/no-show-invoices-report',
  nonFiscal: '/non-fiscalized-invoices',
  fiscal: '/fiscalized-invoices',
  actualRevenue: '/actualized-revenue',
  status: '/status',
  finance: '/finance',
  debit: '/debit',
  invoiceDebit: '/invoice-debit',
  payment: '/payment',
  revenue: '/revenue',
  roomRevenue: '/room-revenue',
  maintainance: '/maintainance',
  employeeStats: '/employee-stats',
  managerReport: '/manager-report',
  allReports: '/all-categories-reports',
  F_and_B_services: '/f-and-b-services.tsx',
  AllPriceAndAvailiblity: '/AllPriceAndAvailiblity.tsx',
  Availability_Room: '/availability-room',
  pricingPlan: '/pricing-plan',
  occupancy: '/occupancy',
  allGuestsReport: '/all-guests-report',
  Departure: '/departure',
  In_house: '/in-house',
  No_Show: '/no-show',
  Daily: '/daily',
  City_tax: '/city-tax',
  statusGuest: '/guest-status',
  houseKeeping: '/house-keeping',
  paceReport: '/pace-report',
  printInvoice: '/print-invoice/',
  printBillingInvoice: '/print-billing-invoice/',
  term_and_condition: '/term-and-condition',
  privacy_policy: '/privacy-policy'
}
