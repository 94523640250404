/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 12/09/2024, 15:28:08
Modified: 08/10/2024, 09:28:45

Description:
  This file contains the function related to access token.
*/

import axios, { AxiosRequestConfig } from 'axios'
import { t } from 'i18next'

import { setUser } from '@/store'
import { setSelectedProperty } from '@/store/slices/properties/selectedProperty'
import { getLocale } from './locale'

let isTokenExpiredAlertShown = false

export const clearUserData = async (dispatch: any, type?: 'token_expired') => {
  const executeFunction = async () => {
    await dispatch(setUser(null))
    await dispatch(setSelectedProperty(null))
    await localStorage.removeItem('userProperty')
    await localStorage.removeItem('userId')
    window.location.reload()
  }

  if (type === 'token_expired') {
    // Show the alert only if it hasn't been shown before
    if (!isTokenExpiredAlertShown) {
      isTokenExpiredAlertShown = true
      if (window.confirm(t('messages.token_expired_please_refresh')!)) {
        await executeFunction()
      }
    }
  } else {
    await executeFunction()
  }
}

export const getAccessToken = ({ getState, dispatch }: any) => {
  const accessToken = getState()?.auth?.user?.user?.token?.access || ''

  if (accessToken) return accessToken
  else {
    clearUserData(dispatch)

    return null
  }
}

export const clearDataIfUnauthorized = async (
  config: AxiosRequestConfig,
  thunkAPI: any
) => {
  const accessToken = getAccessToken(thunkAPI)

  const updatedConfig = {
    ...config,
    headers: {
      'content-type': 'application/json',
      ...config.headers,
      'accept-language': getLocale(),
      Authorization: `Bearer ${accessToken}`
    }
  }

  try {
    const response = await axios(updatedConfig)
    return response.data
  } catch (err: any) {
    if (err?.message === 'Request failed with status code 401') {
      await clearUserData(thunkAPI.dispatch, 'token_expired')
    }

    return null
  }
}
